import { ChangeEvent, PropsWithChildren, useState } from "react";
import { CourseProps, SelectedCourseProps } from "../../store";

import Icon from "../Icon";
import classNames from "classnames";
import styles from "./Course.module.scss";

interface Props {
    data: CourseProps | SelectedCourseProps;
    selected?: boolean;
    allowCopy?: boolean;
    onUpdate?: (id: string, data: any) => void;
    onSelect?: () => void;
    onUnselect?: () => void;
    onDelete?: () => void;
}

const Course: React.FC<PropsWithChildren<Props>> = ({
    data: { id, name },
    selected,
    allowCopy,
    onUpdate,
    onSelect,
    onUnselect,
    onDelete,
    children,
}) => {
    const [copied, setCopied] = useState(false);
    const onBlur = (e: ChangeEvent<HTMLDivElement>) => {
        if (onUpdate && id) {
            onUpdate(id, { name: e.currentTarget.innerText });
        }
    };

    const onCopy = (name: string) => {
        if (!navigator.clipboard) {
            document.execCommand("copy", true, name);
        } else {
            navigator.clipboard
                .writeText(name)
                .then(() => {
                    setCopied(true);
                    const copyInterval = setInterval(() => {
                        setCopied(false);
                        clearInterval(copyInterval);
                    }, 1000);
                })
                .catch(() => {
                    console.error("error copying text");
                });
        }
    };

    const onClickInner = (e: any) => e.target === e.currentTarget && onSelect && onSelect();

    const editable = undefined !== onUpdate;

    return (
        <div className={classNames(styles.course, { [styles.selected]: selected, [styles.selectable]: onSelect })}>
            <div className={styles.inner} onClick={onClickInner}>
                {onSelect && (
                    <Icon type={selected ? "select" : "unselected"} onClick={onSelect} className={styles.iconSelect} />
                )}
                <div className={styles.name}>
                    <div contentEditable={editable} suppressContentEditableWarning onBlur={onBlur}>
                        {name}
                    </div>
                    {editable && <Icon type="edit" className={styles.iconEdit} />}
                </div>

                <div className={styles.slidingArea}>
                    {allowCopy && (
                        <div className={styles.areaCopy} onClick={() => onCopy(name)}>
                            <Icon type={copied ? "clipboard" : "copy"} className={styles.iconCopy} />
                        </div>
                    )}
                    {onUnselect && (
                        <div className={styles.areaUnselect} onClick={onUnselect}>
                            <Icon type="scan" className={styles.iconUnselect} />
                        </div>
                    )}
                    {onDelete && (
                        <div className={styles.areaDelete} onClick={onDelete}>
                            <Icon type="delete" className={styles.iconDelete} />
                        </div>
                    )}
                </div>
            </div>
            {children && <div className={styles.children}>{children}</div>}
        </div>
    );
};

export default Course;
