import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import store, { CourseProps, SelectedCourseProps } from "../../store";

import Course from "../Course";
import classNames from "classnames";
import guid from "../../helpers/guid";
import styles from "./Courses.module.scss";
import { useStore } from "react-create-use-store";

interface Props {}

const Courses: React.FC<Props> = () => {
    const {
        state: { courses, selectedCourses },
        actions: {
            getCourseByName,
            addCourse,
            updateCourse,
            deleteCourse,
            selectCourse,
            unselectCourse,
            resetData,
            factoryReset,
        },
    } = useStore(store);

    const [visibleCourses, setVisibleCourses] = useState<CourseProps[]>(courses);
    const [filterInput, setFilterInput] = useState("");

    useEffect(() => {
        setVisibleCourses(courses);
    }, [courses]);

    useEffect(() => {
        if (0 === filterInput.trim().length) {
            setVisibleCourses(courses);
        } else {
            const filteredCourses = courses.filter((item: CourseProps) => {
                return item.name.toLowerCase().includes(filterInput);
            });
            setVisibleCourses(filteredCourses);
        }
    }, [filterInput, courses]);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => setFilterInput(e.target.value);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (undefined === getCourseByName(filterInput)) addCourse({ id: guid(), name: filterInput });
        setFilterInput("");
    };

    const onSelect = (id: string | undefined) => {
        if (undefined === id) return;
        const selected = 0 < selectedCourses.filter((c: SelectedCourseProps) => c.id === id).length;
        selected ? unselectCourse(id) : selectCourse(id);
    };

    const onFullReset = () => window.confirm("Wirklich alle Einträge löschen?") && resetData();
    const onFactoryReset = () => {
        if (window.confirm("Auf Werkseinstellungen zurücksetzen?")) {
            factoryReset();
            window.location.href = window.location.href;
        }
    };

    return (
        <div className={styles.courses}>
            <div className={styles.head}>
                <h2>Alle Studiengänge</h2>
                <form onSubmit={onSubmit} className={styles.form}>
                    <input
                        placeholder={0 === visibleCourses.length ? "Neuen Studiengang hinzufügen" : "Suche ..."}
                        type="text"
                        onChange={onChange}
                        value={filterInput}
                    />
                    <button type="submit" className={classNames("secondary", styles.btnAdd)}>
                        hinzufügen
                    </button>
                </form>
            </div>

            {0 === visibleCourses.length ? (
                <div className={styles.emptyList}>Keine Einträge vorhanden</div>
            ) : (
                <>
                    <ul className={styles.list}>
                        {visibleCourses
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((data: CourseProps) => {
                                const selected =
                                    0 < selectedCourses.filter((c: SelectedCourseProps) => c.id === data.id).length;
                                return (
                                    <li key={data.id} className={styles.course}>
                                        <Course
                                            data={data}
                                            selected={selected}
                                            onSelect={() => onSelect(data.id)}
                                            onDelete={() => deleteCourse(data.id)}
                                            onUpdate={(id, data) => updateCourse(id, data)}
                                        />
                                    </li>
                                );
                            })}
                    </ul>
                    <hr />
                    <div className={styles.appControls}>
                        <button className="primary" onClick={onFullReset}>
                            alle einträge löschen
                        </button>
                        <button className="primary" onClick={onFactoryReset}>
                            Auf Werkseinstellungen zurücksetzen
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Courses;
