import { createStore, useStore } from "react-create-use-store";

import defaultCourses from "./defaultCourses";
import guid from "../helpers/guid";
import { useEffect } from "react";

// - - - - - - - - - - - - - - - - - - interfaces
export interface CourseProps {
    id: string;
    name: string;
}
export interface SelectedCourseProps extends CourseProps {
    exams: number;
}

export interface StateProps {
    courses: CourseProps[];
    selectedCourses: SelectedCourseProps[];
}

export const localStorageIdentifier = "WINGS_DATA";

// - - - - - - - - - - - - - - - - - - transformers and helpers
const getInitialState = () => {
    const localStorageData = window.localStorage.getItem(localStorageIdentifier);
    let state = {
        courses: defaultCourses.map((a: string) => ({
            id: guid(),
            name: a,
        })),
        selectedCourses: [],
    };
    if (null === localStorageData) return state;
    return JSON.parse(localStorageData);
};

// - - - - - - - - - - - - - - - - - - initial state
const state: StateProps = getInitialState();

// - - - - - - - - - - - - - - - - - - methods
const getCourse = (id: string) => store.state.courses.filter((a: CourseProps) => a.id === id)[0];
const getCourseByName = (name: string) => store.state.courses.filter((a: CourseProps) => a.name === name)[0];
const addCourse = (data: CourseProps) => {
    store.setState((old: StateProps) => {
        return {
            ...old,
            courses: [...old.courses, data],
        };
    });
};
const updateCourse = (id: string, data: any) =>
    store.setState((old: StateProps) => {
        const coursesIndex = old.courses.findIndex((obj) => obj.id === id);
        let updatedCourses = old.courses;
        updatedCourses[coursesIndex] = {
            ...updatedCourses[coursesIndex],
            ...data,
        };

        if (-1 === coursesIndex) return old;
        return {
            ...old,
            courses: updatedCourses,
        };
    });
const deleteCourse = (id: string) =>
    store.setState((old: StateProps) => ({
        ...old,
        courses: old.courses.filter((a) => a.id !== id),
        selectedCourses: old.selectedCourses.filter((a) => a.id !== id),
    }));

const selectCourse = (id: string) =>
    store.setState((old: StateProps) => {
        const index = old.selectedCourses.findIndex((obj) => obj.id === id);
        if (-1 === index) {
            return {
                ...old,
                selectedCourses: [...old.selectedCourses, { id, exams: 1 }],
            };
        }
    });
const unselectCourse = (id: string) =>
    store.setState((old: StateProps) => ({
        ...old,
        selectedCourses: old.selectedCourses.filter((a) => a.id !== id),
    }));

const unselectAllCourses = () =>
    store.setState((old: StateProps) => ({
        ...old,
        selectedCourses: [],
    }));
const updateSelectedCourse = (id: string, data: any) =>
    store.setState((old: StateProps) => {
        const index = old.selectedCourses.findIndex((obj) => obj.id === id);
        let updatedCourses = old.selectedCourses;
        updatedCourses[index] = {
            ...updatedCourses[index],
            ...data,
        };
        if (-1 === index) return old;
        return {
            ...old,
            selectedCourses: updatedCourses,
        };
    });

const resetData = () => {
    store.setState({
        courses: [],
        selectedCourses: [],
    });
};
const factoryReset = () => {
    store.setState({
        courses: defaultCourses.map((a: string) => ({
            id: guid(),
            name: a,
        })),
        selectedCourses: [],
    });
};

// - - - - - - - - - - - - - - - - - - list of action functions
const actions = {
    getCourse,
    getCourseByName,
    addCourse,
    updateCourse,
    deleteCourse,
    selectCourse,
    unselectCourse,
    unselectAllCourses,
    updateSelectedCourse,
    resetData,
    factoryReset,
};

// - - - - - - - - - - - - - - - - - - create and export the store
const store = createStore({
    state,
    actions,
});
export default store;

export const StoreWrapper = ({ children }: any) => {
    const { state } = useStore(store);
    useEffect(() => {
        window.localStorage.setItem(localStorageIdentifier, JSON.stringify(state));
    }, [state]);

    return children;
};
