import { useMemo, useState } from "react";

import { ReactComponent as IconMinus } from "./assets/minus.svg";
import { ReactComponent as IconPlus } from "./assets/plus.svg";
import classNames from "classnames";
import store from "../../store";
import styles from "./SelectedCourses.module.scss";
import { useStore } from "react-create-use-store";

interface Props {
    id: string;
    exams: number;
}

const AdditionalInfo: React.FC<Props> = ({ id, exams }) => {
    const {
        actions: { updateSelectedCourse },
    } = useStore(store);
    const [numExams, setNumExams] = useState<number>(exams);

    useMemo(() => {
        updateSelectedCourse && updateSelectedCourse(id, { exams: numExams });
    }, [id, numExams, updateSelectedCourse]);

    const increase = () => setNumExams((old) => old + 1);
    const decrease = () => setNumExams((old) => (0 >= old ? 0 : old - 1));

    return (
        <fieldset>
            <legend>Anzahl der Prüfungen</legend>
            <div className={styles.exams}>
                <input
                    type="number"
                    value={numExams}
                    className={styles.inputNumExams}
                    onChange={(e) => setNumExams(parseInt(e.target.value, 10))}
                />
                <div className={styles.buttons}>
                    <div className={styles.iconPlus}>
                        <IconPlus onClick={increase} />
                    </div>
                    <div className={classNames(styles.iconMinus, { disabled: 1 >= numExams })}>
                        <IconMinus onClick={decrease} />
                    </div>
                </div>
            </div>
        </fieldset>
    );
};

export default AdditionalInfo;
