const defaultCourses = [
    "After Sales Management",
    "AK Regionale Wirtschaftskreisläufe",
    "Allgemeine und Biologische Psychologie",
    "Anwendungsprogrammierung",
    "Betreuungsrecht und Aufgabenkreise",
    "Betriebssysteme",
    "Bilanzierung",
    "Bilanzanalyse und IFRS",
    "Buchführung",
    "Buchführung und Bilanzierung",
    "Bilanzen",
    "Bilanzen und Unternehmensplanung",
    "Controlling",
    "Cross-Channel-Management",
    "Das Gesundheitswesen als Sektor der deutschen Volkswirtschaft",
    "Datenbanken in Unternehmen",
    "Der Betreuer als Unternehmer_Finanzmanagement, Buchführung und Rechnungslegung",
    "Einführung in das Bürgerliche Recht",
    "Einführung in das Controlling",
    "Einführung in das Projektmanagement",
    "Einführung in die Betriebswirtschaftslehre",
    "Einführung in die BWL",
    "Einführung in die Steuerlehre_Steuern im Sport",
    "Enterprise Resource Planning",
    "Ethical Hacking",
    "Financial Accounting in Gesundheitsunternehmen",
    "Finanzmathematik",
    "Geschäftsmodellberatung bei Unternehmensgründungen",
    "Gesundheitsrecht",
    "Grundlagen der Finanzierung",
    "Grundlagen der Gesundheitsökonomie",
    "Grundlagen der Informatik",
    "Grundlagen der Krankenversicherung",
    "Grundlagen des deutschen Gesundheitssystems",
    "Grundlagen des Marketing",
    "Grundlagen des Rechts_Recht im Sport",
    "Grundlagen des Vertriebes",
    "Grundlagen Familien- und Erbrecht",
    "Investitions- und Finanzierungsberatung",
    "Insolvenzrecht",
    "Internationaler Vertrieb",
    "Kaufmännisches Gebäudemanagement I",
    "Kostenrechnung",
    "Makroökonomie",
    "Marketing-Controlling",
    "Markt-, Konsumenten- und Medienpsychologie",
    "Marktforschung und Marketingplanung",
    "Mathematische Methoden und Anwendungen",
    "Medizinische Grundbegriffe",
    "Mergers and Acquisitions-Consulting",
    "Metrologie",
    "Operations Research",
    "Operatives Kostenmanagement",
    "Organisation",
    "Organisations- und Personalentwicklung",
    "Personalführung und Change Management",
    "Personalinformationssysteme",
    "Personalwirtschaft",
    "Produkt-, Preis-, Distributionsmanagement und Kommunikationspolitik",
    "Programmierung I: Grundlagen der Programmierung",
    "Projekt- und Prozessmanagement",
    "Recht im Gesundheitswesen II",
    "Rechtslehre I",
    "Sanierungsberatung",
    "Sozialethik",
    "Sozialpolitische Grundlagen und Inklusion",
    "Soziologie",
    "Spezielles Sportrecht",
    "SPF Personalmanagement als interne Kundenorientierung",
    "Staats-und verwaltungsrechtliche Grundlagen des Sozialrechts",
    "Statistik",
    "Statistische Forschungsmethoden",
    "Statistische Methoden im Sportmanagement",
    "Statistische Tolerierung",
    "Steuerlehre",
    "Strategisches Management",
    "Systemanalyse und Softwarearchitektur",
    "SWPF EDV-gestützte BWL",
    "Unternehmensführung",
    "Unternehmensrecht",
    "Vereins- und Verbandsmanagement",
    "Verkaufspsychologie",
    "Versorgungsforschung",
    "Verwaltungslehre",
    "Volkswirtschaftslehre II",
    "Vorkurs Programmierung",
    "Wirtschaftsinformatik",
    "Wirtschaftspolitik",
    "Wirtschaftsprivatrecht I Grundlagen",
    "Wirtschaftsprivatrecht II Vertiefung",
    "Wissenschaftliche Methoden",
    "WPM Allgemeine und Biologische Psychologie",
    "WPM Gesundheitspsychologie",
    "WPM Organisation",
    "WPM Wirtschaftspolitik",
    "WPM III Organistations- und Personalentwicklung",
    "Zerstörungsfreie Werkstoffprüfung",
];
export default defaultCourses;
