import { ReactComponent as CustomClipboard } from "./assets/clipboard.svg";
import { ReactComponent as CustomCopy } from "./assets/copy.svg";
import { ReactComponent as CustomDelete } from "./assets/delete.svg";
import { ReactComponent as CustomEdit } from "./assets/edit.svg";
import { ReactComponent as CustomScan } from "./assets/scan.svg";
import { ReactComponent as CustomSelect } from "./assets/select.svg";
import { ReactComponent as CustomUnselected } from "./assets/unselected.svg";

const iconList = {
    clipboard: CustomClipboard,
    copy: CustomCopy,
    delete: CustomDelete,
    edit: CustomEdit,
    scan: CustomScan,
    select: CustomSelect,
    unselected: CustomUnselected,
} as const;

export type IconType = keyof typeof iconList;

export interface IconProps extends Omit<React.SVGAttributes<SVGElement>, "width" | "height"> {
    type: IconType;
    size?: number;
}

const Icon: React.FC<IconProps> = ({ type, size = 16, fill = "currentColor", style, ...rest }) => {
    const Element = iconList[type];
    return <Element {...rest} style={{ display: "inline-flex", ...style }} width={size} height={size} fill={fill} />;
};

export default Icon;
