import Courses from "./components/Courses";
import Icon from "./components/Icon";
import React from "react";
import SelectedCourses from "./components/SelectedCourses";
import { StoreWrapper } from "./store";

const App: React.FC = () => {
    return (
        <StoreWrapper>
            <div className="App">
                <header>
                    <h1>WINGS-FERNSTUDIUM</h1>
                    <h2>Studieren von überall</h2>
                    <h3>Kurzbeschreibung</h3>
                    <p>
                        Dies ist ein Hilfe-Tool bei der Prüfungsaufsicht. Man kann aus einer Liste von Studiengängen die
                        tagesaktuellen Kurse auswählen, die Anzahl der geschriebenen Prüfungen eintragen und hat am Ende
                        ein Kontrollwerkzeug an der Hand, um effektiver zu arbeiten. Alle Daten werden lokal auf dem
                        jeweiligen Gerät gespeichert und eine Internetverbindung ist nicht notwendig.
                    </p>
                    <div className="help">
                        <div>Erklärung</div>
                        <div>
                            <Icon type="select" /> Auswählen
                        </div>
                        <div>
                            <Icon type="delete" /> Löschen
                        </div>
                        <div>
                            <Icon type="copy" /> Titel in Zwischenablage kopieren
                        </div>
                        <div>
                            <Icon type="scan" /> Alles fertig gescannt
                        </div>
                    </div>
                </header>
                <main>
                    <Courses />
                    <SelectedCourses />
                </main>
                <footer>
                    &#169; 2023 | <a href="https://www.stefanknop.de">stefanknop.de</a>
                </footer>
            </div>
        </StoreWrapper>
    );
};

export default App;
