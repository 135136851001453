import store, { SelectedCourseProps } from "../../store";
import { useEffect, useState } from "react";

import AdditionalInfo from "./AdditionalInfo";
import Course from "../Course";
import styles from "./SelectedCourses.module.scss";
import { useStore } from "react-create-use-store";

interface Props {}

const SelectedCourses: React.FC<Props> = () => {
    const {
        state: { selectedCourses },
        actions: { getCourse, unselectCourse, unselectAllCourses },
    } = useStore(store);

    const [courseList, setCourseList] = useState<SelectedCourseProps[]>([]);

    useEffect(() => {
        setCourseList(
            selectedCourses.map((course: SelectedCourseProps) => ({
                ...course,
                name: getCourse(course.id).name,
            }))
        );
    }, [selectedCourses, getCourse]);

    if (0 === selectedCourses.length) return null;

    return (
        <aside>
            <div className={styles.selectedCourses}>
                <h2>
                    Tagesauswahl{" "}
                    <button className="secondary small" onClick={() => unselectAllCourses()}>
                        zurücksetzen
                    </button>
                </h2>

                <ul className={styles.list}>
                    {courseList
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((data: SelectedCourseProps) => (
                            <li key={data.id}>
                                <Course data={data} allowCopy onUnselect={() => unselectCourse(data.id)}>
                                    <AdditionalInfo id={data.id} exams={data.exams} />
                                </Course>
                            </li>
                        ))}
                </ul>
            </div>
        </aside>
    );
};

export default SelectedCourses;
